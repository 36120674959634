import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from './common/layout';
import Card from './common/card';

const MessageBanner = styled.div`
    background: rgb(244, 248, 251);
    padding: 2rem;
`;

const renderNoResults = () => {
    return (
        <MessageBanner className="text-center">
            <h4>We are working 🦫 towards creating the docs 📃 for the following section.</h4>
            <h4>
                Until then please <Link to="/"> check </Link> here for different docs available.
            </h4>
            <h4>Who knows! 🤷‍♂️ Might pick your interest</h4>
        </MessageBanner>
    );
};

const renderResults = (docs) => {
    return docs.map(({ excerpt, frontmatter, fields }, nodeIndex) => (
        <div className="col-4 mb-4" key={`index_page_key_${nodeIndex}`}>
            <Card
                slug={fields.slug}
                title={frontmatter.title}
                excerpt={excerpt}
                imgsrc={frontmatter.imgsrc}
            />
        </div>
    ));
};

const View = (props) => {
    const { docs } = props;
    return (
        <Layout>
            <div className="row p-4">
                {docs.length !== 0 ? renderResults(docs) : renderNoResults()}
            </div>
        </Layout>
    );
};

export default View;
